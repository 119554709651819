import { Button } from "@magnetic/button";
import { Flex } from "@magnetic/flex";
import { Notification } from "@magnetic/notification";
import { Text } from "@magnetic/text";
import React, { useState } from 'react';
import { fetchApi } from '../../../helpers/api/fetch-api';
import { Organization, PreAuthorizationForView } from '../../../helpers/pre-authorization-transformer';
import OrganizationSelector from '../OrganizationSelector';
import ScopesTable from '../ScopesTable';
import "./index.scss";

type AuthorizationApprovalProps = {
  apiBasePath: string;
  preAuthorizationData: PreAuthorizationForView;
};

type OrganizationAccessResponse = {
  canAuthorize: boolean;
}

const AuthorizationApproval = ({ apiBasePath, preAuthorizationData }: AuthorizationApprovalProps) => {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>(undefined);
  const [userHasAccessToOrg, setUserHasAccessToOrg] = useState<boolean>(true);

  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";

  const FormHiddenValues = () => (
    <>
      <input autoComplete="off" name="authenticity_token" type="hidden" value={csrf} />
      <input name="client_id" type="hidden" value={preAuthorizationData.applicationId} />
      <input name="redirect_uri" type="hidden" value={preAuthorizationData.redirectUri} />
      <input name="response_type" type="hidden" value={preAuthorizationData.responseType} />
      <input name="scope" type="hidden" value={preAuthorizationData.scopes.map(scope => scope.name).join(" ")} />
      <input name="code_challenge" type="hidden" value={preAuthorizationData.codeChallenge} />
      <input name="code_challenge_method" type="hidden" value={preAuthorizationData.codeChallengeMethod} />
      <input name="state" type="hidden" value={preAuthorizationData.state} />
      <input name="response_mode" type="hidden" value={preAuthorizationData.responseMode} />
      <input name="organization_id" type="hidden" value={selectedOrganization?.id} />
    </>
  );

  return (
    <div>
      <OrganizationSelector organizations={preAuthorizationData.organizations} selectedOrganization={selectedOrganization} onChange={async (orgName) => {

        const organization = preAuthorizationData.organizations.find((organization) => organization.name === orgName);
        setSelectedOrganization(organization);

        if(organization) {
          /* eslint-disable @typescript-eslint/no-unused-vars */
          const [response, _errors] = await fetchApi<OrganizationAccessResponse>("GET", `/oauth/authorization/access/${organization?.id}`);
          setUserHasAccessToOrg(response?.data?.canAuthorize || false);
        }
      }} />
      { !userHasAccessToOrg &&
        <Notification status={"negative"} style={{ marginBottom: "20px" }}>
          You do not have permission to authorize {preAuthorizationData.applicationName} for this organization.
        </Notification>
      }
      <ScopesTable applicationName={preAuthorizationData.applicationName} applicationScopes={preAuthorizationData.scopes.filter(scope => scope.visible)} />
      <Text size="p3" weight="regular" style={{ marginTop: "20px", marginBottom: "10px" }}>
        By clicking Allow, you allow {preAuthorizationData.applicationName} to use your information in accordance with their terms of service and privacy policies.
      </Text>
      <Flex justify="flex-end" align="center">
        <form action={apiBasePath} method="post">
          {/* https://softwareengineering.stackexchange.com/questions/114156/why-are-there-no-put-and-delete-methods-on-html-forms/211790#211790 */}
          <input type="hidden" name="_method" value="DELETE" />
          <FormHiddenValues />
          <Button value="Decline" type="submit" kind="teritary" className={"negative-button"}>Deny</Button>
        </form>
        <form action={apiBasePath} method="post">
          <FormHiddenValues />
          <Button value="Authorize" type="submit" kind="primary" disabled={!selectedOrganization || !userHasAccessToOrg}>Allow</Button>
        </form>
      </Flex>
    </div>
  );
};

export default AuthorizationApproval;
