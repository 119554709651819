import { CopyToClipboard } from "@magnetic/copy-to-clipboard";
import { Container, Flex, Heading } from '@magnetic/core';
import { Text } from "@magnetic/text";
import React from 'react';

type AuthorizationInfoProps = {
  clientId: string;
}

const AuthorizationInfo = ({ clientId }: AuthorizationInfoProps) => {
  return (
    <Container style={{ marginBottom: '30px' }}>
      <Heading size="section">Authorization</Heading>
      <Text size="p2" color='light' style={{ marginBottom: "20px" }}>This app uses OAuth to integrate with a user’s Meraki account</Text>
      <Container.Divider />
      <Flex gap='xl' align='center' justify={'space-between'}>
        <Flex direction="vertical" style={{width: "40%"}}>
          <Heading size="sub-section">Client ID</Heading>
          <Text size='p4' color='light'>A unique identifier for your app, used to initiate OAuth</Text>
        </Flex>
        <CopyToClipboard
          label=''
          contained
          value={clientId}
          style={{width: "50%"}}
        />
      </Flex>
      <Container.Divider />
      <Flex gap='xl' align='center' justify={'space-between'}>
        <Flex direction="vertical" style={{width: "40%"}} >
          <Heading size="sub-section">Client Secret</Heading>
          <Text size='p4' color='light'>A confidential text string known only to the client (your app) and the authorization server (Meraki), used to initiate OAuth. </Text>
          <Text size='p4' color='light' style={{ marginTop: "20px" }}>This should be stored securely, and never be hard-coded directly into source code. Cisco does not store or retrieve client secrets. If lost, you may generate a new secret.  </Text>
        </Flex>
        <Container.Well style={{ width: "50%", padding: "12px" }}>
          <Text>•••• •••••••• •••• •••• ••••••••</Text>
        </Container.Well>
      </Flex>
    </Container>
  );
};

export default AuthorizationInfo;