import { Container, Flex, Heading } from '@magnetic/core';
import { TextArea } from "@magnetic/text-area";
import { Text } from "@magnetic/text";
import { Application } from '../../../helpers/models';
import { Input } from "@magnetic/input";
import React from 'react';
import { Notification } from '@magnetic/notification';

type BasicInfoProps = {
  application: Application;
  setApplicationHandler: (application: Application) => void;
}

const BasicInfo = ({ application, setApplicationHandler }: BasicInfoProps) => (
  <Container style={{ marginBottom: '30px' }}>
    <Heading size="section">Basic Info</Heading>
    <Text size="p2" color='light' style={{ marginBottom: "20px" }}>Name and callback URI of your app</Text>
    <Container.Divider />
    <Flex gap='xl' align='center' justify={'space-between'}>
      <Flex direction="vertical" style={{width: "30%"}}>
        <Heading size="sub-section">Name</Heading>
        <Text size='p4' color='light'>Application's name</Text>
      </Flex>
      <Flex direction="vertical" gap="md" style={{ width: "60%" }}>
        <Input
          invalid={application.name.length === 0}
          label=""
          value={application.name}
          onChange={(event) => setApplicationHandler({ ...application, name: event.target.value })}
        />
        {
          application.name.length === 0 && (
            <Notification status="negative">
              Application must have a name.
            </Notification>
          )
        }
      </Flex>
    </Flex>
    <Container.Divider />
    <Flex gap='xl' justify={'space-between'}>
      <Flex direction="vertical" style={{width: "30%"}}>
        <Heading size="sub-section">Redirect URIs</Heading>
        <Text size='p4' color='light'>Users will be redirected to this location after granting access to your app</Text>
      </Flex>
      <Flex direction="vertical" gap="md" style={{width: "60%"}}>
        <TextArea
          invalid={application.redirectUris.length === 0}
          value={application.redirectUris}
          label=""
          placeholder="https://application.com/callback&#10;https://application.com/oauth2/redirect"
          onChange={(event) => setApplicationHandler({ ...application, redirectUris: event.target.value })}
        />
        {
          application.redirectUris.length === 0 && (
            <Notification status="negative">
              Application must have at least one redirect URI.
            </Notification>
          )
        }
      </Flex>
    </Flex>
  </Container>
);

export default BasicInfo;