import { Button } from '@magnetic/button';
import { Flex, Heading } from '@magnetic/core';
import { Modal } from '@magnetic/modal';
import { Text } from "@magnetic/text";
import { fetchApi } from '../../../helpers/api/fetch-api';
import { ApiBasePathContext } from '../../app';
import React, { useContext } from 'react';

type DeleteApplicationModalProps = {
  shouldDisplayModal: boolean;
  closeModalHandler: () => void;
  onCancelHandler: () => void;
  onConfirmHandler: () => void;
  applicationId: string;
}

const DeleteApplicationModal = ({ shouldDisplayModal, closeModalHandler, onCancelHandler, onConfirmHandler, applicationId }: DeleteApplicationModalProps) => {
  const apiBasePath = useContext(ApiBasePathContext);

  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";
  
  return (
    <>
      <Modal isOpen={shouldDisplayModal} onClose={closeModalHandler}>
        <Flex direction="vertical">
          <Heading style={{ marginBottom: '20px' }}>Delete application</Heading>
          <Text style={{ marginBottom: '30px' }}>
            Are you sure you want to remove this application. This action cannot be undone.
          </Text>
          <Flex justify="flex-end">
            <Button.Group>
              <Button kind="tertiary" onClick={onCancelHandler}>
                Cancel
              </Button>
                <Button kind="secondary" type="submit" destructive onClick={async () => {
                  const authOptions = {
                    auth: {
                      csrfToken: csrf
                    }
                  };

                  await fetchApi(
                    "DELETE",
                    `${apiBasePath}/${applicationId}`,
                    {},
                    authOptions
                  );

                  onConfirmHandler();
                }}>
                  Confirm
                </Button>
            </Button.Group>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default DeleteApplicationModal;
